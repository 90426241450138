import * as React from 'react';

import {dateFormat, getBackgroundUrl} from '@common/typescript/Utils';

import '@app/scss/components/cardTestimonial.scss';
import { TestimonialCard } from '@app/objects/TestimonialCard';
import LinkMore from '@app/components/LocalCommon/LinkMore/LinkMore';
import {getImagePath} from '@app/objects/Image';

const SliderTesCard: React.FC<TestimonialCard> = (props) => {
	const {
		id, vetName, vetAvatar, authorFirstName, authorLastName, location, text, date, avatarHeight = 100, avatarWidth = 100, extraText = '',
		onHandleClick = () => {}
	} = props;

	const [maxCountLetters, setMaxCountLetters] = React.useState(360);
	const sizes = [
		[330, 190],
		[400, 232],
		[420, 280],
		[768, 570],
		[1024, 243]
	];
	React.useEffect(() => {
		let item;
		for (let i = 0; i < sizes.length; i++) {
			item = sizes[i];
			if (window.matchMedia(`screen and (max-width: ${item[0]}px)`).matches) {
				setMaxCountLetters(item[1]);
				break;
			}
		}
	}, []);

	return (
		<article className="testimonial">
			<header className="testimonial__vet">
				{vetAvatar &&
				<div
					className="testimonial__vet-img"
					style={{backgroundImage: getBackgroundUrl(getImagePath(vetAvatar, avatarHeight, avatarWidth))}}
				/>
				}
				<span className="testimonial__vet-name">{vetName}</span>
			</header>
			{text && text.length &&
			<div
				className={`testimonial__text ${text.length > maxCountLetters ? 'testimonial__text__with-continue' : ''}`}>
				{text}
			</div>
			}
			{text && (text.length > maxCountLetters) &&
			<LinkMore
				linkMoreCaption="Read more"
				linkMoreLink={'#'}
				handleCLick={onHandleClick}
			/>
			}
			<footer className="testimonial__author">
				{!authorFirstName && !authorLastName ? '' : <span className="testimonial__author-name">{`${authorFirstName} ${authorLastName}`}</span>}
				<span className="testimonial__author-location">{extraText && `${extraText}, `}{location}</span>
				{date &&
				<span className="testimonial__author-date">{dateFormat(date, {
					day: 'numeric',
					month: 'long',
					year: 'numeric'
				})}</span>
				}
			</footer>
		</article>
	);
};

export default SliderTesCard;