import * as React from 'react';

import '@app/scss/components/cardPhoto.scss';

const CardPhoto = (props) => {
	const {imgURL, title, alt} = props;

	return (
		<article className="card-photo">
			<div className="card-photo__img">
				<img src={imgURL} alt={alt}/>
			</div>
			<p className="card-photo__caption">{title}</p>
		</article>
	);
};

export default CardPhoto;