import * as React from 'react';

import CardLink from '@app/components/UI/CardLink/CardLink';

const CardLinks = ({list}) => {
	return(
		<div className="card-links">
			{list.map((item, index) =>
				item.url ?
				<CardLink
					key={index}
					url={item.url}
					title={item.title}
					description={item.description}
				/> :
					<div className="card-link card-no-link">
						<h4 className="card-link__title">{item.title}</h4>
						{item.description && <p className="card-link__text">{item.description}</p>}
					</div>
			)}
		</div>
	);
};

export default CardLinks;