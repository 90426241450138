import * as React from 'react';

import arrow from '@images/arrow_circle.svg';

const CardLink = (props) => {
	return(
		<div className="card-link">
			<h4 className="card-link__title"><a href={props.url} target="_blank">{props.title}</a></h4>
			{props.description && <p className="card-link__text">{props.description}</p>}
			{props.url && (
				<>
					<a href={props.url} className="card-link__link" target="_blank">{props.url}</a>
					<a href={props.url} className="link-more" target="_blank">
						<img src={arrow} alt="arrow" className="link-more-img"/>
					</a>
				</>
			)}
		</div>
	);
};

export default CardLink;