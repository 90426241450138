import * as React from 'react';
import {Link} from 'react-router-dom';

import '@app/scss/components/fiftyFifty.scss';

interface FiftyFiftyProps {
	title: string | JSX.Element;
	text: string | JSX.Element;
	src: string;
	alt?: string;
	btnText?: string;
	btnPath?: string;
	reversed?: boolean;
	openInNewTab?: any;
	name?: string;
	extraBlock?: JSX.Element;
	refFiftyFifty?: any;
}

const FiftyFifty: React.FC<FiftyFiftyProps> = (props) => {
	// console.log(props.name, 'NAME');
	return (
		<section
			className={`fifty-fifty ${props.reversed ? 'fifty-fifty_reversed' : ''}`}
			id={props.name} ref={props.refFiftyFifty!}>
			<section className="fifty-fifty-block">
				<h2 className="fifty-fifty__title">{props.title}</h2>
				<p className="fifty-fifty-block_text">{props.text}</p>
				{props.btnPath && props.btnText && (
					<div className="fifty-fifty__btn">
						<Link className="btn btn-primary" onClick={() => props.openInNewTab(props.btnPath)} to="/angel-fund">{props.btnText}</Link>
					</div>
				)}
				{
					props.extraBlock ? props.extraBlock : null
				}
			</section>
			<div className="fifty-fifty-block">
				<img className="fifty-fifty__img" src={props.src} alt={props.alt || ''}/>
			</div>
		</section>
	);
};

export default FiftyFifty;