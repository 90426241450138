import * as React from 'react';
import {Link} from 'react-router-dom';

import arrow from '@app/images/arrow_circle.svg';

const LinkMore = ({
	linkMoreCaption = '',
	linkMoreLink,
	handleCLick = e => e.stopPropagation(),
	classNameAdd = '',
	target = '_self',
	linkBack = false
}) => {
	return(
		<Link
			to={linkMoreLink}
			target={target}
			className={linkBack ? `link-more link-more__back ${classNameAdd}` : `link-more ${classNameAdd}`}
			onClick={handleCLick}
		>
			<span>{linkMoreCaption}</span>
			<img src={arrow} alt="arrow" className="link-more-img"/>
		</Link>
	);
};

export default LinkMore;