import * as React from 'react';

interface SloganBlockSmallProps {
	title: string;
	btnText: string | JSX.Element;
	onBtnClick?: any;
	text?: string | JSX.Element;
	styled?: string;
}

const SloganBlockSmall: React.FC<SloganBlockSmallProps> = ({title, btnText, onBtnClick, text, styled}) => {
	return(
		<div className={'slogan-block-small' + `${styled ? ` ${styled}` : ''}` } >
			<div className="slogan-block-small__header">
				<h2 className="slogan-block-small__title">
					{title}
				</h2>
				{
					typeof btnText === 'string' ?
						<button onClick={onBtnClick} className="btn btn-md btn-primary slogan-block-small__btn">
							{btnText}
						</button> :
						btnText
				}
			</div>
			
			<div className="slogan-block-small__bottom"/>
			{
				text ? text : null
			}
		</div>		
	);
};

export default SloganBlockSmall;