import * as React from 'react';

import {FormikProps} from 'formik';

import {FileInterface} from '@common/typescript/objects/FileInterface';

import '@app/scss/localCommon/components/attachment.scss';

const fileIcon = require('@app/images/white-sheets.svg');

interface AttachmentPreviewProps {
	attachment: FileInterface[];
	setAttachment: (value: FileInterface[]) => void;
	formikBag: FormikProps<any>;
}
const FileThumbSkeleton = () => {
	return(
		<div className={'file-thumb-skeleton'}>
			<img src={fileIcon} alt="file-icon"/>
		</div>
	);
};

const AttachmentPreview: React.FC<AttachmentPreviewProps> = ({attachment, setAttachment, formikBag}) => {
	const imgExtension = ['jpg', 'jpeg', 'png'];
	return(
		<div className="attachment-preview__wrapper">
			{attachment ? attachment.map(file => (
				<div
					className="attachment-preview"
					key={file.id}
				>
					{
						imgExtension.some(extension => extension === file.extension.toLowerCase()) ? <img src={file.thumb} alt="thumb"/> :
						<FileThumbSkeleton />
					}
					<span>{file.name}</span>
					<button
						type="button"
						className="ant-modal-close attachment-preview-delete"
						onClick={() => {
							setAttachment(attachment.filter(item => item.id !== file.id));
							formikBag.setFieldValue('filePath', formikBag.values['filePath'].filter(item => item !== file.src));
						}}
					>
						<span className="ant-modal-close-x"><span
							role="img" aria-label="close"
							className="anticon anticon-close ant-modal-close-icon"><svg
							viewBox="64 64 896 896" focusable="false"
							data-icon="close" width="1em" height="1em"
							fill="currentColor" aria-hidden="true"><path
							d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z" />
							</svg>
						</span>
						</span>
					</button>
				</div>
			)) : null
			}
		</div>
	);
};

export default AttachmentPreview;