import * as React from 'react';

interface MiddleTextProps {
	title?: string;
	text?: string | any;
	style?: any;
}

const MiddleText: React.FC<MiddleTextProps> = ({ title, text, children, style }) => (
	<article className="middle-text">
		{title && <h4 className="middle-text__title">{title}</h4>}
		{text && <div className="middle-text__text" dangerouslySetInnerHTML={{__html: text}} style={style ? style : null}/>}
		{children}
	</article>
);

export default MiddleText;
