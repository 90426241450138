import * as React from 'react';
import LazyLoad from 'react-lazy-load';

import {Image} from '@app/objects/Image';
import {LinkBlockItemRich} from '@app/objects/LinkBlockItem';
import LinkMore from '@app/components/LocalCommon/LinkMore/LinkMore';
import {WithPath} from '@app/objects/WithPath';
import arrow from '@app/images/arrow_circle.svg';

interface BlockRichProps {
	title: string | JSX.Element;
	text?: string | JSX.Element;
	subtitle?: string;
	images?: [Image, Image, Image];
	links?: LinkBlockItemRich[];
	buttonCaption?: string;
	linkMoreCaption?: WithPath[];
	name?: string;
	blockClassName?: string;
	link?: string;
	refBlockRich?: any;
}

const BlockRich: React.FC<BlockRichProps> = ({link = '', ...props }) => (
	<section className={`link-block-rich ${props.blockClassName ? props.blockClassName : ''}`} id={props.name} ref={props.refBlockRich!}>
		<div className="link-block-rich__block-left">
			<div className="link-block-rich__wrapper">
				<header className="link-block-rich-header">
					{props.subtitle && <h6 className="link-block-rich-header__subtitle">{props.subtitle}</h6>}
					<h2 className="link-block-rich-header__title">{props.title}</h2>
				</header>
				{props.text &&
					<div className="link-block-rich__descr">
						<p>
							{props.text}
						</p>
						{props.linkMoreCaption && props.linkMoreCaption.length > 1 &&
							<>
								{props.linkMoreCaption[0] && <LinkMore linkMoreCaption={props.linkMoreCaption[0].name} linkMoreLink={props.linkMoreCaption[0].path} />}
								{props.linkMoreCaption[1] && <LinkMore linkMoreCaption={props.linkMoreCaption[1].name} linkMoreLink={props.linkMoreCaption[1].path} />}
							</>
						}
						{props.linkMoreCaption && props.linkMoreCaption.length === 1 &&
							<a
								href={props.linkMoreCaption[0].path}
								target={'_blank'}
								className={'link-more'}
							>
								<span>{props.linkMoreCaption[0].name}</span>
								<img src={arrow} alt="arrow" className="link-more-img"/>
							</a>
						}
					</div>
				}
				{   props.buttonCaption &&
					<a className="link-block-rich__link-donate" href={link} target="_blank">
						<button className="btn btn-md btn-primary">
							{props.buttonCaption }
						</button>
					</a>

				}
			</div>
		</div>
		{props.images &&
			<div className="link-block-rich__block-right">
				<div className="link-block-rich-col link-block-rich-col_double">
					{props.images.slice(0, 2).map((image: Image, key: number) => (
						<div className="image-wrapper" key={key}>
							<a className="image-wrapper__inner" href={image.link}>
								<LazyLoad offset={1000} height={image.width ?? 250}>
									<img style={image.style} className="image-wrapper__img" src={image.src}
										 alt={image.alt || ''}/>
								</LazyLoad>
							</a>

							<label className="image-wrapper__title">{image.title}</label>
						</div>
					))}
				</div>
				<div className="link-block-rich-col link-block-rich-col_single">
					<div className="image-wrapper">
						<a className="image-wrapper__inner" href={props.images[2].link}>
							<LazyLoad offset={1000} height={props.images[2].width ?? 300}>
								<img style={props.images[2].style} className="image-wrapper__img" src={props.images[2].src}
									 alt={props.images[2].alt || ''}/>
							</LazyLoad>
						</a>
						<label className="image-wrapper__title">{props.images[2].title}</label>
					</div>
				</div>
			</div>
		}
	</section>
);

export default BlockRich;