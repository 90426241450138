import * as React from 'react';

import Upload from 'antd/lib/upload';
import { LoadingOutlined, PlusSquareOutlined } from '@ant-design/icons';

import Button from '@common/react/components/Forms/Button';
import {FileType, FileInterface} from '@common/typescript/objects/FileInterface';

import {request} from '@app/components/Api';

interface FileState {
	isLoading: boolean;
	error: string | null;
}

interface BaseFileProps {
	type: string;
	objectId: number;
	onError?: (error: string) => void;
	fileType?: FileType;
	infoMessage?: string;
	asButton?: boolean;
	accept?: string;
	buttonCaption?: string | JSX.Element;
	buttonClassName?: string;
	disabled?: boolean;
	multiple?: boolean;
	noRelation?: boolean; // Whether to create relation for uploaded file or not
	additionalData?: object;
	transformData?: (data: FormData) => FormData; // Transform data before sending to server
	request?: string;
}

interface FileProps extends BaseFileProps {
	update: (data: FileInterface) => void;
	showError?: boolean;
}

interface CustomRequestParams {
	action: any;
	data: any;
	file: any;
	filename: any;
	headers: any;
	onError: any;
	onProgress: any;
	onSuccess: any;
	withCredentials: any;
}

interface UploadFileParams {
	file: any;
	type: string;
	objectId: number;
	fileType: FileType;
	additionalData?: object;
	headers?: any;
	request?: string;
}

export const uploadFile = (params: UploadFileParams, transformData?: (data: FormData) => FormData): Promise<any> => {
	const formData = new FormData();

	formData.append('file', params.file);
	formData.append('objectType', params.type);
	formData.append('objectId', params.objectId.toString());
	formData.append('fileType', params.fileType.toString());
	
	if (params.additionalData) {
		const data = params.additionalData;
		Object.keys(data).forEach(key => formData.append(`data[${key}]`, data[key].toString()));
	}

	return fetch(params.request || 'fineUploader', {
		credentials: 'same-origin',
		method: 'POST',
		headers: params.headers,
		body: transformData ? transformData(formData) : formData
	})
	.then(response => response.json())
	.then(((response) => {
		if (!response.success) throw response.response;
		return response.response;
	}))
	.catch((error) => {
		console.log(error.message);
		throw error;
	});
};

export default class File extends React.Component<FileProps, FileState> {
	public static defaultProps: Partial<FileProps> = {
		infoMessage: 'Image size must not exceed 1 Mb',
		asButton: false,
		accept: '',
		buttonCaption: 'Upload',
		objectId: -1,
		showError: true,
	};
	
	constructor(props : FileProps) {
		super(props);

		this.state = {
			isLoading: false,
			error: null
		};

		this.customRequest = this.customRequest.bind(this);
	}
	
	customRequest(argument) {
		const {file, headers} = argument as CustomRequestParams;

		const { type, objectId, fileType = FileType.Avatar, additionalData, noRelation, transformData, request } = this.props;
		
		if (noRelation) {
			headers['No-Relation'] = 'true';
		}

		this.setState({ 
			isLoading: true,
			error: null
		});
		
		uploadFile({
			file,
			fileType,
			objectId,
			type,
			additionalData,
			headers,
			request
		}, transformData)
		.then((data: FileInterface) => {
			this.setState({ isLoading: false });
			this.props.update(data);
		}).catch((error) => {
			this.setState({
				isLoading: false,
				error: error.message
			});

			if (this.props.onError) {
				this.props.onError(error.message);
			}
		});
	}

	render(): JSX.Element {
		const {asButton, accept, infoMessage, buttonCaption, buttonClassName} = this.props;
		
		let uploadButton;
		
		if (asButton) {
			uploadButton = <React.Fragment>
				<Button type="button" className={buttonClassName} isLoading={this.state.isLoading} disabled={this.props.disabled}>{buttonCaption}</Button>
				{this.props.showError !== false && <div className="file-upload-error">{this.state.error}</div>}
			</React.Fragment>;
		} else {
			uploadButton = (
				<React.Fragment>
					{this.state.isLoading ? <LoadingOutlined /> : <PlusSquareOutlined />} 
					<div className="ant-upload-text">{buttonCaption}</div>
					<div className="ant-upload-info">{this.state.error || infoMessage}</div>
				</React.Fragment>
			);
		}
		
		return <Upload 
			className={`avatar-uploader ${this.state.error ? 'avatar-uploader_error' : ''}`}
			listType={this.props.asButton ? 'text' : 'picture-card'}
			showUploadList={false}
			accept={accept}
			customRequest={this.customRequest}
			disabled={this.props.disabled}
			multiple={this.props.multiple}
		>
			{uploadButton}
		</Upload>;
	}
}

interface CustomFileProps extends BaseFileProps {
	onUploadType: string;
	onUpload: (data: any) => void;
}

export class CustomFile extends React.Component<CustomFileProps, {}> {
	
	constructor(props: CustomFileProps) {
		super(props);
		
		this.update = this.update.bind(this);
	}

	update(data: FileInterface) {
		request(this.props.onUploadType, {
			fileId: data.id
		}).then((response: any) => {
			// response cast to any for new typescript support
			const image = {
				...response,
				file: data
			};
			
			this.props.onUpload(image);
		}).catch(() => {
			debugger;
		});
	}
	
	render() {
		return <File update={this.update} {...this.props}/>;
	}	
}