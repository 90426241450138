declare const window: Window & { dataLayer: Record<string, unknown>[]; };

export function pushEvent(event: string, data: object) {
	window.dataLayer.push({
		event: event,
		...data
	});
}

export function pushSocialShare(socialNetwork: 'Facebook' | 'Twitter' | 'LinkedIn' | 'Email', socialTarget: string) {
	pushEvent('socialShare', {
		socialNetwork: socialNetwork,
		socialTarget: socialTarget
	});
}

export function pushIconClick(icon: string) {
	pushEvent('iconClick', {
		icon: icon,
	});
}

export function pushFileDownload(fileName: string) {
	pushEvent('files', {
		name: fileName,
	});
}
