import * as React from 'react';

interface RichTextProps {
	title?: string;
	text: string | JSX.Element;
	name?: string;
	subtitle?: string;
	refRichText?: any;
}

const RichText: React.FC<RichTextProps> = ({ title, text, name, subtitle, refRichText }) => (
	<section className="rich-text" id={name}>
		{title && <h3 className="rich-text__title" ref={refRichText}>{title}</h3>}
		{subtitle && <h6 className="rich-text__title">{subtitle}</h6>}
		<div className="rich-text__text">{text}</div>
	</section>
);

export default RichText;
