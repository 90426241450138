import * as React from 'react';

import NavSelectMobile from '@app/components/UI/NavSelectMobile/NavSelectMobile';
interface  NavItem {
	name: string;
	title: string;
}
interface NavTabsProps {
	navItems: NavItem[];
	currentIndex: number;
	onSetIssue: (e: number) => void;
	navCollapse?: boolean;
	onChange?: (e: number) => void;
}

const NavTabs: React.FC<NavTabsProps> = ({navItems, currentIndex , onSetIssue,  navCollapse, onChange}) => {
	return (
		<>
			{
				!navCollapse ?
				<div className="nav-tabs-custom">
					{
						navItems && navItems.map((item, index) =>
							(<button
								key={index}
								className={currentIndex === index ? 'nav-tabs__item active' : 'nav-tabs__item'}
								onClick={() => onSetIssue(index)}
							>
								{item.title}
							</button>))
					}
				</div> :
				<NavSelectMobile 
					navItems={navItems} 
					onChange={onChange} 
					currentIndex={currentIndex}
				/>
				
			}
		</>
		
	);
};

export default NavTabs;