export const transformStateList = (stateList, stateListOptions) => {
	stateList && stateList.map((item, index) => {
		// console.log(item, 'STATE_ITEM');
		stateListOptions.push({value: item.name, label: `${item.name} (${item.code})`});
	});
};

export const transformVetList = (list, options) => {
	list && list.map((item, index) => {
		options.push({value: `Dr. ${item.firstName} ${item.lastName}`, id: item.id, label: `Dr. ${item.firstName} ${item.lastName}`});
	});
};