import React from 'react';

export interface Image {
	src: string;
	alt?: string;
	title: string | JSX.Element;
	style?:  React.CSSProperties;
	link?: string;
	width?: number;
}

export function getImagePath(avatar: string | undefined | null, height?: number, width?: number): string {
	if (!avatar) {
		return '';
	}

	const getArguments = (height?: number, width?: number, heightParam?: string, widthParam?: string, prefix?: string) => {
		const heightString = height ? `${heightParam}=${height}` : '';
		const widthString = width ? `${widthParam}=${width}` : '';

		const params = [heightString, widthString].join('&');

		if (params) {
			return (prefix || '') + params;
		}

		return '';
	};

	if (avatar.indexOf('http') === -1) {
		return `${avatar}${getArguments(height, width, 'height', 'width', '?')}`;
	}

	return `api/resize?src=${encodeURIComponent(avatar)}${getArguments(height, width, 'maxHeight', 'maxWidth', '&')}`;
}