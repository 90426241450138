import * as React from 'react';

import Modal from 'antd/lib/modal';

import CardTestimonial from '@app/components/UI/CardTestimonial/CardTestimonial';

const ModalTestimonial = (
	{modalVisible, setModalVisible, id, authorFirstName, authorLastName, vetName = '', vetAvatar, location, text, getContainer = 'body', extraText = ''}
) => {
	return (
		<Modal
			wrapClassName="modal-wrap modal-wrap-testimonial"
			visible={modalVisible}
			onOk={() => setModalVisible(false)}
			onCancel={() => setModalVisible(false)}
			width={859}
			getContainer={getContainer}
			centered
		>
			<div className="modal-testimonial">
				<CardTestimonial
					id={id}
					vetName={vetName}
					authorFirstName={authorFirstName}
					authorLastName={authorLastName}
					vetAvatar={vetAvatar}
					location={location}
					text={text}
					extraText={extraText}/>
			</div>
		</Modal>
	);
};

export default ModalTestimonial;