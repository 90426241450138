import * as React from 'react';

import {AutoComplete, Input} from 'antd';

interface CustomAutoCompleteProps {
	allowClear?: boolean;
	placeholder: string;
	field: any;
	onSelect?: any;
	onChange: (value: string, option: any) => void;
	options?: any[];
	onBlur: () => void;
	label?: string | number;
	setStateFormLabel?: (e: string) => void;
	adjustValue?: boolean;
	customFilter?: (input: string, option: string) => boolean;
}

const CustomAutoComplete: React.FC<CustomAutoCompleteProps> = (
	{options, placeholder, field, onSelect, onChange, onBlur, label, allowClear = false, setStateFormLabel, adjustValue, customFilter}) => {

	const setAdjustedValue = (item) => {
		field.form.setFieldValue(field.field.name, item.value);
		setStateFormLabel && setStateFormLabel(item.label);
	};

	const resetAdjustedValue = () => {
		field.form.setFieldValue(field.field.name, '', false);
		setStateFormLabel && setStateFormLabel('');
		field.form.setFieldError(field.field.name, 'Choose correct state name from list');
	};

	const passAdjustedValue = () => {
		if (options && options.length) {
			const value = field.field.value.toLowerCase();

			const possibleValues = options.filter(item => item.label.toLowerCase().indexOf(value) !== -1)
				.sort((a, b) => a.label.toLowerCase().indexOf(value) - b.label.toLowerCase().indexOf(value));

			const result = possibleValues.filter(item => item.value.toLowerCase() === value);

			if (result.length) {
				setAdjustedValue(result[0]);
			} else {
				resetAdjustedValue();
			}
			
			return;
		}

		resetAdjustedValue();
	};

	return(
		<AutoComplete
			allowClear={allowClear}
			options={options}
			placeholder={placeholder}
			// @ts-ignore
			autoComplete={'new-password'}
			onSelect = {onSelect}
			onChange = {onChange}
			onBlur = {() => {
				adjustValue && passAdjustedValue();
				onBlur();
			}}
			value={field.field.value === undefined ? '' : label ? label : field.field.value}
			dropdownClassName="custom-field-dropdown"
			filterOption={(inputValue, option= {value: 'not selected'}) => {
				if (customFilter) {
					return customFilter(inputValue, option.label || option.value);
				}

				return (
					option.label && typeof option.label === 'string' ?
						option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 :
						option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
				);
			}}
		>
			<Input />
		</AutoComplete>
	);
};

export default CustomAutoComplete;