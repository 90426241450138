import * as React from 'react';
import {Link} from 'react-router-dom';
interface FaqBlockHeaderSm {
	title: string;
	btnText: string;
	clickUrl: string;
}
const FaqBlockHeaderSm = ({title, btnText, clickUrl}) => {
	return (
		<div className="faq-block-header-sm">
			<h2>{title}</h2>
			<Link to={clickUrl}>
				<button className="btn btn-primary btn-md">
					{btnText}
				</button>
			</Link>
		</div>
	);
};
export default FaqBlockHeaderSm;