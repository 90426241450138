import React from 'react';

import { Field } from 'formik';

export interface CustomCheckboxProps {
	isChecked: boolean;
	setIsChecked: (e: boolean) => void;
	formikBag: any;
	capture: any;
	className?: string;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
	isChecked,
	setIsChecked,
	formikBag,
	capture,
	className = '',
}) => {
	return (
		<Field
			name="isDisplayed"
			render ={() => {
				return (
					<div className={`custom-checkbox ${className}`}>
						<input
							type="checkbox"
							checked={isChecked}
							// @ts-ignore
							value={isChecked}
						/>
						<label onClick={() => {
							setIsChecked(!isChecked);
							formikBag.setFieldValue('isDisplayed', !isChecked);
						}}>
							{capture}
						</label>
					</div>
				);
			}}
		/>
	);
};

export default CustomCheckbox;