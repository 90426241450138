import * as React from 'react';

import Modal from 'antd/lib/modal';

import { addSubtitlesToYoutubeVideos } from '@app/components/LocalCommon/Utils/VideoExtensions';

const ModalVideo = ({modalVisible, setModalVisible, videoSrc, getContainer = 'body'}) => {
	return (
		<Modal
			wrapClassName="modal-wrap modal-wrap-video"
			visible={modalVisible}
			onOk={() => setModalVisible(false)}
			onCancel={() => setModalVisible(false)}
			width={859}
			centered
			getContainer={getContainer}
			destroyOnClose={true}
		>
			<div className="modal-video">
				<iframe
					className="video-embed-block__video"
					src={addSubtitlesToYoutubeVideos(videoSrc)}
					width="697"
					height="392"
					frameBorder="0"
					allow="autoplay; fullscreen"
					allowFullScreen
				/>
			</div>
		</Modal>
	);
};

export default ModalVideo;