import * as React from 'react';
import {Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';

import Breadcrumb from 'antd/lib/breadcrumb';

import {CustomMenuItem} from '@app/objects/CustomMenuItem';
import LinkMore from '@app/components/LocalCommon/LinkMore/LinkMore';

const arrowIcon = require('@app/images/arrow_circle.svg');

interface MustheadBaseProps {
	title: string | JSX.Element;
	imageSrc: string;
	imageAlt?: string;
	text?: string;
	quoteAuthor?: string;
	buttonText?: string | JSX.Element;
	breadcrumbs?: CustomMenuItem[];
	backPath?: CustomMenuItem;
	btnPath?: string;
	onBtnClick?: any;
	to?: string;
	other?: React.ReactNode;
}

type MustheadProps = MustheadBaseProps & (
	{ text: string; quoteAuthor: string } |
	{ text: string; } |
	{ imageSrc: string; } |
	{ breadcrumbs: CustomMenuItem[], backPath: CustomMenuItem }
);

const Musthead: React.FC<MustheadProps> = (props) => {
	const [loaded, setLoaded] = React.useState(false);
	const imgRef = React.useRef<HTMLImageElement>(null);
	const mustheadRef = React.useRef<any>();

	function onLoad() {
		setLoaded(true);
	}

	React.useEffect(() => {
		mustheadRef.current.scrollIntoView({ block: 'end', inline: 'nearest' });

		if (imgRef.current?.complete) {
			onLoad();
		}
	}, []);

	return (
		<>
			<Helmet>
				<meta name="image" property="og:image" content={`https://www.lapoflove.com/${props.imageSrc}`.replace('com//', 'com/')} />
			</Helmet>
			<div className="musthead">
				<div className="musthead-wrapper" ref={mustheadRef!}>
					<div className={!loaded ? 'musthead-body' : 'musthead-body musthead-body_loaded'}>
						<div className="musthead-body__left">
							{props.breadcrumbs && (
								<Breadcrumb>
									{props.breadcrumbs.map((bc, index) => (
										<Breadcrumb.Item key={bc.path + index}>
											{bc.path ? (
												<Link to={bc.path}>{bc.name}</Link>
											) : (
												bc.name
											)}
										</Breadcrumb.Item>
									))}
								</Breadcrumb>
							)}
							<h1 className="musthead-body__title">{props.title}</h1>
							{props.quoteAuthor && (
								<blockquote className="musthead-body-quote">
									<p className="musthead-body-quote__text">“{props.text}”</p>
									<footer className="musthead-body-quote__footer">~ {props.quoteAuthor}</footer>
								</blockquote>
							)}

								<div className="musthead-body-block">
									{props.text && !props.quoteAuthor &&
									<p className="musthead-body-quote__text-sm">{props.text}</p>
									}
									{props.other && props.other}
									{props.buttonText && (typeof props.buttonText === 'string' ?
										<Link onClick={() => props.onBtnClick(props.btnPath)} to={props.to}>
											<button type="button" className="btn btn-primary">{props.buttonText}</button>
										</Link> : props.buttonText)
									}
								</div>
							{props.backPath && (
								<LinkMore linkMoreLink={props.backPath.path} classNameAdd="musthead-link-back" linkBack={true} linkMoreCaption={props.backPath.name}/>
							)}
						</div>
						<div className="musthead-body__image-wrapper">
							<img
								ref={imgRef}
								className="musthead-body__image"
								src={props.imageSrc}
								alt={props.imageAlt || ''}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Musthead;