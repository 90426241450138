import React from 'react';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';

import {actionCreators, PageItemState} from '@common/react/store/PageItem';

import {BaseContentPage, PageContent} from '@app/objects/ContentPage';
import {ApplicationState} from '@app/store';

const withSeo = (
	WrappedComponent,
	pageSelector: (state: ApplicationState) => PageItemState<BaseContentPage>,
	storageName: string,
	path: string,
	showLapOfLoveTitle = true) => {

	const wrapper: React.FC<PageContent & typeof actionCreators> = (props) => {
		React.useEffect(() => {
			props.loadPage(storageName, path);
		}, []);

		return (
			<>
				{props.title && <Helmet>
					<title>{`${props.title}${showLapOfLoveTitle && ' | Lap of Love' || ''}`}</title>
					<meta name="description" property="og:description" content={props.description || undefined}/>
				</Helmet>}
				<WrappedComponent {...props} />
			</>
		);
	};

	return connect<PageContent, any, any, ApplicationState>(
		(state) => {
			return {
				title: null,
				description: null,
				...pageSelector(state).page?.content
			};
		},
		actionCreators
	)(wrapper);
};

export default withSeo;