import * as React from 'react';

import Collapse from 'antd/lib/collapse';

import {Issues, Questions} from '@app/objects/Questions';
import LinkMore from '@app/components/LocalCommon/LinkMore/LinkMore';

import '@app/scss/components/faqBlock.scss';

const {Panel} = Collapse;

interface FaqBlockProps {
	questions: Questions[];
	carrotIcon?: string;
	maxCount?: number;
	linkCategory?: string;
	category?: Issues;
	showCategory?: (category?: Issues) => void;
}

const FaqBlock: React.FC<FaqBlockProps> = ({
		questions,
		carrotIcon = require('@app/images/carrot_white.svg'),
		maxCount = questions.length,
		category,
		linkCategory,
		showCategory
	}) => {
	
	const questionsArray = questions.slice(0, maxCount);
	
	return (

			<Collapse
				className="faq-block-body-questions"
				expandIcon={() => <img className="carrot-icon" src={carrotIcon} alt=""/>}
			>
				{questionsArray.map((question: Questions, index: number) => (
					<Panel key={question.name + index} header={question.name}>
						<p dangerouslySetInnerHTML={{__html: question.text}} />
					</Panel>
				))}
				{questions.length > maxCount && (
					<div className="faq-block-body-questions-more btn-text">
						<LinkMore 
							linkMoreLink={linkCategory} 
							linkMoreCaption="View more"
							handleCLick={() => showCategory && showCategory(category)}
						/>
					</div>
				)}
			</Collapse>
	);
};

export default FaqBlock;
