import * as React from 'react';

import {Field} from 'formik';

import CustomAutoComplete from '@app/components/Forms/CustomAutoComplete/CustomAutoComplete';
import {ListOption} from '@app/objects/SidebarProps';

const searchIcon = require('@app/images/search_white.svg');

interface SearchFieldAutoProps {
	placeholder: string;
	name: string;
	formikBag: any;
	onSelect?: (value: string, option: ListOption) => void;
	onChange: (value: string, option: any) => void;
	onSearch?: () => void;
	searchButton: boolean;
	buttonType?: 'button' | 'submit' | 'reset' | undefined;
	listOptions?: ListOption[];
	allowClear?: boolean;
	label?: string | number;
	setStateFormLabel?: (e: string) => void;
	adjustValue?: boolean;
	customFilter?: (input: string, option: string) => boolean;
}

const SearchFieldAuto: React.FC<SearchFieldAutoProps> = ({
 	placeholder,
	name,
	listOptions,
	onSelect,
	onChange,
	buttonType= 'button',
	onSearch,
	searchButton,
	allowClear,
	label,
	setStateFormLabel,
 	adjustValue,
	customFilter
}) => {

	return (
		<div className={searchButton ? 'search-field search-field_auto search-field_button' : 'search-field search-field_auto'}>
		<Field
			name={name}
			render={(field) => {
				return(
					<div className={field.form.errors[name] && field.form.touched[name] ? 'custom-field error' : 'custom-field'}>
						<CustomAutoComplete
							placeholder={placeholder}
							options={listOptions}
							field={field}
							onSelect = {onSelect}
							onChange = {onChange}
							onBlur = {() => field.form.setFieldTouched(name, true)}
							allowClear={allowClear}
							label={label}
							setStateFormLabel={setStateFormLabel}
							adjustValue={adjustValue}
							customFilter={customFilter}
						/>
						{
							field.form.errors[name] && field.form.touched[name] ? <div className="custom-input__error"> {field.form.errors[name]}</div> : null
						}
					</div>
				);
			}}
		/>
		{
			searchButton &&
			<button type={buttonType} className="search-field-button" onClick={onSearch}>
				<img src={searchIcon} alt="Search Icon" />
			</button>
		}

		</div>

	);
};

export default SearchFieldAuto;