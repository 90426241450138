import * as React from 'react';
import {Link} from 'react-router-dom';

import {LinkBlockItemActive} from '@app/objects/LinkBlockItem';

interface LinkBlockIconsProps {
	items: LinkBlockItemActive[];
	title?: '';
}

const LinkBlockSmall: React.FC<LinkBlockIconsProps> = ({ items, title= 'View our other services' }) => (
	<div className="link-block-small">
		<div className="link-block-small-wrapper">
			<h4 className="link-block-small__title">{title}</h4>
			<div className="link-block-small__links">
				{items.map((item, index) => (
					<Link className="link-item" to={item.path} key={item.path + index} >
						<h5 className={`link-item__title ${item.active ? 'highlight' : ''}`}>{item.name}</h5>
					</Link>
				))}
			</div>
		</div>
		<div className="link-block-small-bottom" />
	</div>
);

export default LinkBlockSmall;
