import * as React from 'react';

import { addSubtitlesToYoutubeVideos } from '@app/components/LocalCommon/Utils/VideoExtensions';

import '@app/scss/components/videoEmbed.scss';

interface VideoEmbedProps {
	text?: string | JSX.Element;
	videoSrc?: string;
	videoTitle?: string;
}

const VideoEmbed: React.FC<VideoEmbedProps> = props => (
	<section className={`video-embed ${props.videoSrc ? 'video-embed_with-video' : ''}`}>
		{props.text && <div className="video-embed__text">{props.text}</div>}
		{props.videoSrc && (
			<div className="video-embed-block">
				<iframe
					className="video-embed-block__video"
					src={addSubtitlesToYoutubeVideos(props.videoSrc)}
					width="697"
					height="392"
					frameBorder="0"
					allow="autoplay; fullscreen"
					allowFullScreen
				/>
				{props.videoTitle && <p className="video-embed-block__title">{props.videoTitle}</p>}
			</div>
		)}
	</section>
);

export default VideoEmbed;
