import React from 'react';
import {
	ComposableMap,
	Geographies,
	Geography,
} from 'react-simple-maps';

import Tooltip from 'antd/lib/tooltip';

import { BaseState } from '@app/objects/BaseState';

const geoUrl = 'https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json';

interface MapProps<T extends BaseState> {
	states: T[];
	onClick: (selectedState:T) => void;
	getTooltip: (selectedState:T) => string;
	getFillColor: (selectedState:T) => string;
}

const SimpleMap = <T extends BaseState, >(props: React.PropsWithChildren<MapProps<T>>) => {
	const [color, setColor] = React.useState('#71677e');

	const geographyClick = (currentState) => {
		props.onClick && props.onClick(currentState);
	};

	const geographyMove = (currentState) => {
		const vendor = window.navigator.vendor;
		const userAgent = window.navigator.userAgent;

		setColor('#9c8cae');

		if (userAgent.toLowerCase().match(/(ipad|iphone)/) && vendor.match(/Apple Computer, Inc./)) {
			setTimeout(() => props.onClick && props.onClick(currentState), 0);
		}
	};

	return (
		<ComposableMap projection="geoAlbersUsa">
			<Geographies geography={geoUrl}>
				{({ geographies }) => (
					<>
						{geographies.map((geo) => {
							const currentState = props.states.find(state => state.name === geo.properties.name);

							if (currentState) {
								return (
									<Tooltip
										title={props.getTooltip(currentState)}
										key={geo.rsmKey}
										overlayClassName="find-vet-tooltip"
									>
										<Geography
											stroke="#FFF"
											geography={geo}
											fill={color}
											className="find-vet-geography"
											style={{
												default: {
													fill: props.getFillColor(currentState),
													outline: 'none'
												},
												pressed: {
													fill: '#71677e',
													outline: 'none'
												}
											}}
											onClick={() => geographyClick(currentState)}
											onMouseMove={() => geographyMove(currentState)}
										/>
									</Tooltip>
								);
							}
						})}
					</>
				)}
			</Geographies>
		</ComposableMap>
	);
};

export default SimpleMap;
