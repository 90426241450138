import * as React from 'react';
import {Link, NavLink} from 'react-router-dom';

import {Form, Formik, FormikProps} from 'formik';

import SearchFieldAuto from '@app/components/Forms/SearchField/SearchFieldAuto';
import {eventToLink} from '@app/components/LocalCommon/Utils/eventToLink';

import '@app/scss/components/blockSideBar.scss';

interface SidebarProps {
	searchFieldName?: string;
	searchFieldPlaceHolder?: string;
	onSetIssue: any;
	navCollapse: boolean;
	topics: any;
	topicsInner?: any;
	withSubmit: boolean;
	submitCaption?: string;
	onSubmit?: (e: string) => void;
	typeTopicsInner?: string;
	isSearchFieldNeeded?: boolean;
	typePage?: string;
	searchFieldValue?: string;
	setSearchFieldValue?: (e: string) => void;
}

export const additionalResources = [
	{
		name: 'HowIKnow',
		url: 'how-will-i-know-it-is-time',
		title: 'How Will I Know It\'s Time'
	},
	{
		name: 'Calendar',
		url: 'quality-of-life-calendar.pdf',
		title: 'Pet Quality of Life Calendar'
	},
	{
		name: 'Newsletter',
		url: 'newsletter'
	},
	{
		name: 'Blog',
		url: 'blog'
	},
	{
		name: 'Videos',
		url: 'videos'
	},
	{
		name: 'FAQ',
		url: 'faq'
	},
];

const SidebarWithCaptions: React.FC<SidebarProps> = (
	{
		searchFieldName = '',
		searchFieldPlaceHolder = '',
		onSetIssue,
		navCollapse,
		topics,
		onSubmit,
		typeTopicsInner,
		topicsInner,
		isSearchFieldNeeded = true,
		typePage = '',
		searchFieldValue,
		setSearchFieldValue
	}) => {

	const [isOpen, setIsOpen] = React.useState(false);
	const [searchActive, setSearchActive] = React.useState(false);

	const callMenu = () => {
		setIsOpen(!isOpen);
		setSearchActive(false);
	};

	const callSearch = () => {
		setSearchActive(true);
	};

	return(
		<div className="block__sidebar">
			<Formik
				onSubmit={(values) => {
				}}
				initialValues={{searchQuery: ''}}

				render={(formikBag: FormikProps<any>) => {
					return(
						<Form>
							{isSearchFieldNeeded &&
								<SearchFieldAuto
									name={'searchQuery'}
									placeholder={searchFieldPlaceHolder}
									formikBag={formikBag}
									onSelect={(value, option) => {
									}}
									onChange={(value, option) => {
										setSearchFieldValue && setSearchFieldValue(value);
										!value && onSubmit && onSubmit('') && setSearchFieldValue && setSearchFieldValue('');
									}}
									onSearch={() => {
										searchFieldValue ? onSubmit && onSubmit(searchFieldValue) : null;
									}}
									searchButton={true}
									buttonType="submit"
									allowClear={true}
									label={searchFieldValue}
								/>
							}
							<div className={isOpen ? 'block__sidebar-nav active-mobile' : 'block__sidebar-nav'}>
								{topics && topics.length > 0 &&
									<ul className="block__sidebar-nav-list sidebar-menu">
										{typePage === 'NEWS_PAGE' &&
											<li className="sidebar-menu__section">
												<h6 className="sidebar-menu__section-title block__sidebar-nav-list-caption">In the News</h6>
												<ul className="sidebar-menu__section-content">
													{topics.map((item, indexInner) => (
														<li key={indexInner} className="sidebar-menu__section-content__item">
															<NavLink
																to={`/in-the-news/${item.url}`}
																className="sidebar-menu__section-content__item-link"
																activeClassName="active"
																onClick={() => {
																	onSetIssue(item);
																}}>
																{item.name}
															</NavLink>
														</li>
													))}
												</ul>
											</li>
										}
										{typePage === 'VET_FAQ' &&
											<>
											<li className="sidebar-menu__section">
												<h6 className="sidebar-menu__section-title block__sidebar-nav-list-caption">Veterinary Access</h6>
												<ul className="sidebar-menu__section-content">
													<li  className="sidebar-menu__section-content__item">
														<Link
															to={'/veterinary-resources/login'}
															className="sidebar-menu__section-content__item-link"
															>
															Login
														</Link>
													</li>
												</ul>
											</li>
											<li className="sidebar-menu__section">
												<h6 className="sidebar-menu__section-title block__sidebar-nav-list-caption">Veterinary Resources</h6>
												<ul className="sidebar-menu__section-content">
													{topics.map((item, indexInner) => (
														<li key={indexInner} className="sidebar-menu__section-content__item">
															<NavLink
																to={`${item.url}`}
																className="sidebar-menu__section-content__item-link"
																activeClassName="active"
																onClick={() => {
																	onSetIssue(item);
																}}>
																{item.name}
															</NavLink>
														</li>
													))}
												</ul>
											</li>
											</>
										}
										{!typePage &&
											<>
											<li className="sidebar-menu__section">
												<h6 className="sidebar-menu__section-title block__sidebar-nav-list-caption">Resource
													Topics</h6>
												<ul className="sidebar-menu__section-content">
													{topics.map((item, indexInner) => (
														<li key={indexInner} className="sidebar-menu__section-content__item">
															<NavLink
																to={`/resource-center/${item.url}`}
																className="sidebar-menu__section-content__item-link"
																activeClassName="active"
																onClick={() => {
																	onSetIssue['Common'](item);
																}}>
																{item.name}
															</NavLink>
														</li>
													))}
												</ul>
											</li>
											<li className="sidebar-menu__section">
												<h6 className="sidebar-menu__section-title block__sidebar-nav-list-caption">Additional Resources</h6>
												<ul className="sidebar-menu__section-content">
													{additionalResources.map((resource, indexInner) => (
														<li key={indexInner} className="sidebar-menu__section-content__item">
															{
																resource.url !== 'quality-of-life-calendar.pdf' ?
																	<NavLink
																		to={`/${resource.url}`}
																		className="sidebar-menu__section-content__item-link"
																		activeClassName="active"
																		onClick={() => {
																			onSetIssue[resource.name](resource);
																		}}>
																		{resource.title ? resource.title : resource.name}
																	</NavLink>
																:  <Link
																		to={`/${resource.url}`}
																		target="_blank"
																		className="sidebar-menu__section-content__item-link"
																		onClick={() => {
																			onSetIssue[resource.name](resource);
																			eventToLink(resource.title ? resource.title : resource.name);
																		}}>
																		{resource.title ? resource.title : resource.name}
																	</Link>
															}

															{typeTopicsInner && topicsInner && resource.url === typeTopicsInner &&
																<ul className="sidebar-menu__section-content sidebar-menu__section-content--second-level">
																	{topicsInner[resource.name] ? topicsInner[resource.name].map((topic, indexInner) => {
																		return (
																			<li
																				key={indexInner}
																				className="sidebar-menu__section-content__item">
																				<NavLink
																					to={`/${resource.url}/${topic.name.toLowerCase().replace(/[&]/g, 'and').replace(/\s+/g, '-')}`}
																					className="sidebar-menu__section-content__item-link"
																					activeClassName="active"
																					onClick={() => {
																						onSetIssue[resource.name](topic);
																					}}
																				>
																					{topic.name}
																				</NavLink>
																			</li>
																		);
																	}) : null}
																</ul>
															}
														</li>
													))}
												</ul>
											</li>
											</>
										}
									</ul>
								}
							</div>
						</Form>
					);
				}}
			/>
		</div>
	);
};

export default SidebarWithCaptions;