import * as React from 'react';

import {dateFormat, getBackgroundUrl} from '@common/typescript/Utils';

import '@app/scss/components/cardTestimonial.scss';
import { TestimonialCard } from '@app/objects/TestimonialCard';
import {getImagePath} from '@app/objects/Image';

const CardTestimonial: React.FC<TestimonialCard> = (props) => {
	const {id, vetName, vetAvatar, authorFirstName, authorLastName, location, text, date, avatarHeight = 100, avatarWidth = 100, extraText = ''} = props;

	return (
		<article className="testimonial">
			{vetName &&
				<header className="testimonial__vet">
					{vetAvatar &&
					<div
						className="testimonial__vet-img"
						style={{backgroundImage: getBackgroundUrl(getImagePath(vetAvatar, avatarHeight, avatarWidth))}}
					/>
					}
					<span className="testimonial__vet-name">{vetName}</span>
				</header>
			}
			<section className="testimonial__text">
				{text}
			</section>
			<footer className="testimonial__author">
				{(authorFirstName?.length || authorLastName?.length) && (
					<span className="testimonial__author-name">
						{authorFirstName?.length && authorFirstName} {authorLastName?.length && authorLastName}
					</span>
				)}
				<span className="testimonial__author-location">{extraText && `${extraText}, `}{location}</span>
				{date &&
					<span className="testimonial__author-date">{dateFormat(date, {
						day: 'numeric',
						month: 'long',
						year: 'numeric'
					})}</span>
				}
			</footer>
		</article>
	);
};

export default CardTestimonial;