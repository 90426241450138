import Select from 'antd/lib/select';
import * as React from 'react';
const {Option} = Select;

const NavSelectMobile = ({navItems, onChange, currentIndex}) => {
	return (
		<div className="nav-select-mobile">
			<Select
				size="large"
				value={navItems[currentIndex].title}
				onChange={(value, option) => {
					// @ts-ignore
					onChange(+option.key);
				}}
				dropdownClassName="nav-select-mobile-dropdown"
			>{navItems && navItems.filter(item => !item.hidden).map((item, index) =>

				<Option key ={index} value={item.title}>
					<button
						className={currentIndex === index ? 'nav-select-mobile-button active' : 'nav-select-mobile-button'}
					>
						{item.title}
					</button>
				</Option>
			)
			}</Select>
		</div>
	);
};

export default NavSelectMobile;