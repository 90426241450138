import * as React from 'react';

import '@app/scss/components/mustheadNaked.scss';

interface MustheadNakedBaseProps {
	imageSrc: string;
	imageAlt?: string;
	title?: string;
}

const MustheadNaked: React.FC<MustheadNakedBaseProps> = (props) => {
	return (
		<div className="musthead-naked">
			<div className="musthead-naked-wrapper" style={{backgroundImage: `url(${props.imageSrc})`}}>
				{props.title &&
					<h1 className="musthead-naked__title">{props.title}</h1>
				}
				<img className="musthead-naked__edge" src={require('@app/images/triangle_edge-transparent.png')} />
			</div>
		</div>
	);
};

export default MustheadNaked;
