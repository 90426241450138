import * as React from 'react';

import {EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton} from 'react-share';

import {pushSocialShare} from '@app/components/TagManager';

const FacebookShare = ({url}) => {
	return <FacebookShareButton url={url} onClick={() => pushSocialShare('Facebook', url)} className="facebook-ga-btn">
		<i className="fa fa-facebook" aria-hidden="true"/>
	</FacebookShareButton>;
};

const TwitterShare = ({url}) => {
	return <TwitterShareButton url={url} onClick={() => pushSocialShare('Twitter', url)} className="twitter-ga-btn">
		<i className="fa fa-twitter" aria-hidden="true" />
	</TwitterShareButton>;
};

const EmailShare = ({url}) => {
	return <EmailShareButton
		url={url}
		onClick={(_, link: string) => {
			pushSocialShare('Twitter', url);
			window.location.href = link;
		}}
		className="email-ga-btn">
		<i className="fa fa-envelope" aria-hidden="true" />
	</EmailShareButton>;
};

const LinkedinShare = ({url}) => {
	return <LinkedinShareButton url={url} onClick={() => pushSocialShare('Twitter', url)} className="linkedin-ga-btn">
		<i className="fa fa-linkedin" aria-hidden="true" />
	</LinkedinShareButton>;
};

export {
	FacebookShare,
	TwitterShare,
	EmailShare,
	LinkedinShare
};