import * as React from 'react';

import {Link, useHistory} from 'react-router-dom';

import LinkMore from '@app/components/LocalCommon/LinkMore/LinkMore';

import listenerForBrowser from '@app/components/Pages/listenerForBrowser';

interface DiseaseCardProps {
	name: string;
	shortText: string;
	url: string;
	topic?: string;
}

const arrowIcon = require('@app/images/arrow_circle.svg');

const DiseaseCard: React.FC<DiseaseCardProps> = ({name, shortText, url, topic }) => {
	const [isMobile, setIsMobile] = React.useState(false);
	const history = useHistory();

	React.useEffect(() => {
		const mql = window.matchMedia('screen and (max-width: 768px)');

		const listenerFunc = () => {
			if (mql.matches) {
				setIsMobile(true);
			} else {
				setIsMobile(false);
			}
		};
		listenerFunc();
		listenerForBrowser(mql, listenerFunc);
	}, []);

	return(
		<div className="quality__card" onClick={() => history.push(url)}>
			{isMobile &&
				<div className="quality__card-bottom-topic">
					{topic}
				</div>
			}
			<h3 className="quality__card-title" >
				{name}
			</h3>
			<p className="quality__card-text">
				{shortText}
			</p>
			<div className="quality__card-bottom">
				<LinkMore linkMoreCaption="Read more" linkMoreLink={url}/>
				{!isMobile &&
					<div className="quality__card-bottom-topic">
						{topic}
					</div>
				}
			</div>

		</div>
	);
};
export default DiseaseCard;