import {WithId} from '@common/typescript/objects/WithId';

export interface ContentTopicList extends WithId {
	list: ContentEntity[];
	count: number;
	offset: number;
}

export interface ContentTopic {
	id: number;
	name: string;
	url: string;
	description: string;
}

export interface ContentEntity extends WithId {
	avatar: string;
	contentType: ContentType;
	date: number;
	source: string;
	id: number;
	smallText: string;
	fullText: string;
	title: string;
	description: string;
	url: string;
	topic: Topic;
}

export enum ContentType {
	Article,
	ExternalLink,
	Video
}

export interface Topic {
	url: string;
	id: number;
	name: string;
}
