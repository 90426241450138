import * as React from 'react';

import {useHistory} from 'react-router-dom';

import Carousel from 'antd/lib/carousel';
import Skeleton from 'antd/lib/skeleton';

import CardPhoto from '@app/components/UI//CardPhoto/CardPhoto';
import SliderTesCard from '@app/components/UI/Slider/SliderTesCard';
import '@app/scss/components/sliderButtons.scss';
import ModalTestimonial from '@app/components/UI/ModalTestimonial/ModalTestimonial';
import {Testimonials} from '@app/objects/Testimonials';

// import { SliderDotsArrowsProps } from '@app/objects/SliderDotsArrowsProps';
const arrowIcon = require('@app/images/arrow_circle.svg');
const arrowIconHovered = require('@app/images/button_slider.svg');

const SliderDotsArrows: React.FC<any> = ({sliderItems, sliderItemsType}) => {
	const slider = React.useRef<any>();
	const avatarHeight = 120;
	const avatarWidth = 120;
	const [currentIndex, setCurrentIndex] = React.useState(0);
	const [currentVetName, setCurrentVetName] = React.useState('');
	const [currentVetAvatar, setCurrentVetAvatar] = React.useState('');
	const [testimonial, setTestimonial] = React.useState<Testimonials>();
	const [modalVisible, setModalVisible] = React.useState(false);

	return(
		<>
			<div className="slider-dots-arrows__body">
				{(!sliderItems.length) ? <Skeleton title={false} paragraph={{ rows: 1, className: 'slider-tes-skeleton' }} active/> :
					<Carousel
						ref={slider}
						slidesToShow={1}
						// afterChange doesn't seem to work properly, so we use beforeChange instead.
						beforeChange={(from, to) => setCurrentIndex(to)}
						dots={false}
						infinite
						effect="fade"
						adaptiveHeight={true}
					>
						{sliderItemsType === 'cardTes' &&
							sliderItems.map(item => (
									<SliderTesCard
										vetName = {`Dr. ${item.user?.firstName} ${item.user?.lastName}`}
										vetAvatar = {item.user?.avatar}
										authorFirstName = {item.firstName ? item.firstName : ''}
										authorLastName = {item.lastName ? item.lastName : ''}
										location = {`${item.city}, ${item.state}`}
										id = {item.id}
										key = {item.id}
										text = {item.message}
										date = {item.dateCreated}
										avatarHeight={avatarHeight}
										avatarWidth={avatarWidth}
										onHandleClick = {(e) => {
											e.preventDefault();
											setModalVisible(true);
											setTestimonial(item);
											setCurrentVetName(`Dr. ${item.user?.firstName} ${item.user?.lastName}`);
											setCurrentVetAvatar(item.user?.avatar);
										}}
									/>
							))
						}
						{sliderItemsType === 'cardPhoto' &&
							sliderItems.map(item => (
									<CardPhoto
										id = {item.id}
										key = {item.id}
										imgURL = {item.imgURL}
										title = {item.title}
									/>
							))
						}

					</Carousel>
				}
			</div>
			{
				sliderItems.length > 1 &&
				<div className="slider-buttons slider-dots-arrows__buttons">
					<div className="slider-button_left__wrapper">
						<img
							className="slider-button slider-button_left"
							src={arrowIcon}
							alt="Prev"
							onClick={() => slider.current!.prev()}
						/>
						<img
							className="slider-button slider-button_left_hovered"
							src={arrowIconHovered}
							alt="Prev"
							onClick={() => slider.current!.prev()}
						/>
					</div>
					<ul className="slider-dots-arrows__dots">
						{sliderItems.map((item, i) => {
							return <li key={`dot-${item.id}`} className={`slider-dots-arrows__dots-item ${currentIndex === i ? 'active' : ''}`}/>;
						})}
					</ul>
					<div className="slider-button__wrapper">
						<img
							className="slider-button"
							src={arrowIcon}
							alt="Next"
							onClick={() => slider.current!.next()}
						/>
						<img
							className="slider-button slider-button_hovered"
							src={arrowIconHovered}
							alt="Next"
							onClick={() => slider.current!.next()}
						/>
					</div>
				</div>
			}
			{testimonial &&
				<ModalTestimonial
					modalVisible={modalVisible}
					setModalVisible={setModalVisible}
					id={testimonial.id}
					vetName={currentVetName}
					authorFirstName={testimonial.firstName}
					authorLastName={testimonial.lastName}
					vetAvatar={currentVetAvatar}
					location={`${testimonial.city}, ${testimonial.state}`}
					text={testimonial.message}
					getContainer=".area-page-body"
					extraText={testimonial.extraText}
				/>
			}
		</>
	);
};

export default SliderDotsArrows;