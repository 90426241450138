import * as React from 'react';

import '@app/scss/components/sliderButtons.scss';

const arrowIcon = require('@app/images/arrow_circle.svg');
const arrowIconHovered = require('@app/images/button_slider.svg');

const SliderButtons = ({slider}) => {
	return (
		<div className="slider-buttons">
			<div className="slider-button_left__wrapper">
				<img
					className="slider-button slider-button_left"
					src={arrowIcon}
					alt="Prev"
					onClick={() => slider.current!.prev()}
				/>
				<img
					className="slider-button slider-button_left_hovered"
					src={arrowIconHovered}
					alt="Prev"
					onClick={() => slider.current!.prev()}
				/>
			</div>
			<div className="slider-button__wrapper">
				<img
					className="slider-button"
					src={arrowIcon}
					alt="Next"
					onClick={() => slider.current!.next()}
				/>
				<img
					className="slider-button slider-button_hovered"
					src={arrowIconHovered}
					alt="Next"
					onClick={() => slider.current!.next()}
				/>
			</div>
		</div>
	);
};

export default SliderButtons;