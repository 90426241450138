import {validateFile} from '@app/components/LocalCommon/Utils/Validators';

export const onFileUpload = (
	data,
	field,
	formikBag,
	name,
	maxFile,
	imgExtension,
	attachment,
	maxImgCount,
	setAttachment,
	formatErrorCapture = 'Image required to be in JPG or PNG format ',
	) => {

	attachment.length >= maxImgCount ? setAttachment([...attachment.splice(1, attachment.length - 1), data]) : setAttachment([...attachment, data]);

	field.form.setFieldTouched(name, true, false);

	if (!imgExtension.some(el => el === data.extension.toLowerCase())) {
		formikBag.setFieldValue(name, [], false);
		setAttachment([]);
	} if (data.size < maxFile && imgExtension.some(el => el === data.extension.toLowerCase())) {
		formikBag.values[name].length >= maxImgCount ?
			formikBag.setFieldValue(name, [...formikBag.values[name].splice(1, attachment.length - 1), data.src], false) :
			formikBag.setFieldValue(name, !formikBag.values[name].length ? [data.src] : [...formikBag.values[name], data.src], false);

		field.form.setFieldError(name, '');
	}
};