import * as React from 'react';

import {useHistory} from 'react-router-dom';

import {List} from '@common/typescript/objects/List';

import {rest} from '@app/components/Api';
import MiddleText from '@app/components/UI/MiddleText/MiddleText';
import SliderDotsArrows from '@app/components/UI/Slider/SliderDotsArrows';
import {TestimonialsWithVetData} from '@app/objects/Testimonials';
import listenerForBrowser from '@app/components/Pages/listenerForBrowser';

const photos = [
	{
		id: 1,
		imgURL: 'https://lapoflove.com/sunshine/files/5f44ff6a-4fae-43d8-b1ca-0348e7a12256/Dani-McVety-DVM-Lap-of-Love-Hospice-and-In-Home-Euthanasia-Tampa-FL_w.png',
		title: '1 Dr. Dani providing in-home care to a geriatric dog.'
	},
	{
		id: 2,
		imgURL: 'https://lapoflove.com/sunshine/files/5f44ff6a-4fae-43d8-b1ca-0348e7a12256/Dani-McVety-DVM-Lap-of-Love-Hospice-and-In-Home-Euthanasia-Tampa-FL_w.png',
		title: '2 Dr. Dani providing in-home care to a geriatric dog.'
	},
	{
		id: 3,
		imgURL: 'https://lapoflove.com/sunshine/files/5f44ff6a-4fae-43d8-b1ca-0348e7a12256/Dani-McVety-DVM-Lap-of-Love-Hospice-and-In-Home-Euthanasia-Tampa-FL_w.png',
		title: '3 Dr. Dani providing in-home care to a geriatric dog.'
	},
];

const SliderAreaVet = (props) => {
	const {vet} = props;
	const [testimonials, setTestimonials] = React.useState<TestimonialsWithVetData[]>([]);
	const [navCollapse, setNavCollapse] = React.useState<boolean>(false);
	const [paddingTop, setPaddingTop] = React.useState(0);

	const history = useHistory();

	React.useEffect(() => {
		const mql = window.matchMedia('screen and (max-width: 768px)');

		const listenerFunc = () => {
			if (mql.matches) {
				setNavCollapse(true);
			} else {
				setNavCollapse(false);
			}
		};
		listenerFunc();
		listenerForBrowser(mql, listenerFunc);
	}, []);

	const openFilteredTestimonial = () => {
		history.push({
			pathname: '/veterinarian-testimonials',
			state: {
				vetId: testimonials[0].user.id,
				state: '',
				name: `Dr. ${testimonials[0].user.firstName} ${testimonials[0].user.lastName}`,
				navCollapse
			}
		});
	};

	React.useEffect(() => {
		rest.get<List<TestimonialsWithVetData>>(`v1/testimonials/?veterinarianId=${vet.id}`)
			.then(res => setTestimonials(res.list));
	}, []);

	React.useEffect(() => {
		setPaddingTop(document.getElementsByClassName('middle-text__title')[0].clientHeight);
	});

	const handleWindowResize = React.useCallback((event) => {
		setPaddingTop(document.getElementsByClassName('middle-text__title')[0].clientHeight);
	}, []);

	React.useEffect(() => {
		window.addEventListener('resize', handleWindowResize);

		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, [handleWindowResize]);

	return (
		<div style={{position: 'relative'}}>
			<MiddleText title={`About Dr. ${vet.firstName} ${vet.lastName}`} text={vet.biography} style={{paddingTop: `${paddingTop}px`}} />
			{   testimonials.length ?
				<div className={'testimonials-slider'}>
					<SliderDotsArrows sliderItems={testimonials} sliderItemsType="cardTes"/>
					<button
						className="btn btn-primary view-more"
						onClick={openFilteredTestimonial}
					>
						View all testimonials
					</button>
				</div> : null
			}
		</div>
	);
};

export default SliderAreaVet;
